// packages needed by every template
import "aframe-rounded";
import "./shared/polyfills";
import "./shared/service-workers";
import "./shared/assets/styles/globals.scss";

// helper functions
// import { getCurrentRoom } from './shared/modules/workroomvr-app';

// fetches room and determines which code template to run
const init = async () => {
  if (window.location.href.includes("builder") || window.AFRAME.utils.device.isMobile() || window.AFRAME.utils.device.isTablet()) {
    window.PRODUCT_BUILDER = true;
  }

  // setup object to share resources between park and board builder
  window.sharedResources = {};

  await import("./skatepark");
};
init();
